/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { RoleFunction } from '@eagle/common';
import { Alert as AlertType, AlertResponse, Person, PersonType, SharedThingType, Thing, ThingType } from '@eagle/core-data-types';
import { checkLocation, FeatureTypes } from '@eagle/data-function-types';
import {
  Breadcrumbs,
  CacheDataTypes,
  DetailPage,
  DynamicIcon,
  FeatureIcons,
  FetchOne,
  FetchOneOfAll,
  FlexBox,
  FormatAddress,
  FormatTimestamp,
  getNavigateState,
  GroupsCard,
  InlayMap,
  INLAY_MAP_DEFAULT_ZOOM,
  MediaCard,
  MediaListPageType,
  MiddleSpinner,
  PortalFeatureIcons,
  StaticData,
  T_MANY,
  Undefinable,
  useAuthenticated,
  useBoolFlag,
  useCustomRoutes,
  useDynamicModule,
  useFetchOneCache,
  useHasAuthorization,
  usePromise,
  validateLocationType
} from '@eagle/react-common';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { Alert, Box, Button, Card, CardContent, Divider, Link, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import L from 'leaflet';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHref, useLocation, useNavigate, useParams } from 'react-router-dom';
import { check } from 'validata';
import { AlertDetailProps, AlertMapProps, PersonCardProps, ThingCardProps } from './alert-details-types';

interface TimeZoneStackProps {
  date: Date;
  label: string;
  tooltipTitle: JSX.Element;
  zoneAbbreviation: string;
  zoneName: string;
}

interface LocationState {
  previousUrl: string;
}

const VIEW_HISTORY_TIME_DIFF = 15;
const MEDIA_PERMISSIONS = [RoleFunction.MEDIA_VIEWER] as const;

const AlertMap: FC<AlertMapProps> = ({ data }) => {
  const getLocation = (): unknown => data?.location;
  let location;
  try {
    location = check(checkLocation, getLocation);
  }
  catch (e) {
    location = undefined;
  }

  return (
    <FlexBox sx={{ flexGrow: 0, height: '400px' }}>
      <InlayMap
        center={location && L.latLng(location.latitude, location.longitude)}
        drawerLayerSelection
        pinLabel={(location?.address && location?.address.length)
          && <Stack spacing={0.5}>
            <FormatAddress value={location?.address} variant="body2" />
          </Stack>
        }
        zoom={INLAY_MAP_DEFAULT_ZOOM}
      />
    </FlexBox>
  );
};

const TimeZoneStack: FC<TimeZoneStackProps> = ({ date, label, tooltipTitle, zoneName, zoneAbbreviation }): JSX.Element => {
  return (
    <Stack data-chromatic="ignore">
      <Stack direction="row" spacing={0.5}>
        <Typography variant="caption">{label}</Typography>
        <Tooltip arrow followCursor title={tooltipTitle}>
          <Typography variant="caption">
            (<Typography sx={{ textDecoration: 'underline dotted' }} variant="caption">{zoneAbbreviation}</Typography>)
          </Typography>
        </Tooltip>
      </Stack>
      <FormatTimestamp value={date} zone={zoneName} />
    </Stack>
  );
};

export const AlertCard: FC<{ alert: AlertResponse }> = ({ alert }): JSX.Element => {
  const { t } = useTranslation(['common', 'track']);
  const localTimeZone = DateTime.fromJSDate(alert.occurred, { zone: DateTime.now().zoneName }).setLocale(navigator.language);

  const location = validateLocationType(() => alert.data?.location);
  const alertZoneName = location?.timeZone?.id ?? '';
  const alertTimeZone = DateTime.fromJSDate(alert.occurred, { zone: alertZoneName }).setLocale(navigator.language);
  const isSameTimeZone = localTimeZone.offsetNameShort === alertTimeZone.offsetNameShort;

  if (!localTimeZone || !alertTimeZone) {
    return (
      <Card>
        <CardContent>
          <Stack spacing={2}>
            <Typography variant="h5">{t('common:component.alert-table.labels.title')}</Typography>
            <Typography>{t('common:component.alert-table.hint.error')}</Typography>;
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="h5">{t('common:component.alert-table.labels.title')}</Typography>
          {isSameTimeZone || isEmpty(alert.data)
            ? <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
              <FormatTimestamp value={alert.occurred} />
              <Typography variant="caption">
                <FormatTimestamp format="relative" value={alert.occurred} />
              </Typography>
            </Stack>
            : <Stack spacing={2}>
              <TimeZoneStack
                date={alert.occurred}
                label={t('track:page.alert-detail.timezone.labels')}
                tooltipTitle={<>{`${localTimeZone.offsetNameLong} (${localTimeZone.offsetNameShort})`}</>}
                zoneAbbreviation={localTimeZone.offsetNameShort}
                zoneName={localTimeZone.zoneName}
              />
              <Divider />
              <TimeZoneStack
                date={alert.occurred}
                label={t('track:page.alert-detail.local-timezone.labels')}
                tooltipTitle={<>{`${alertTimeZone.offsetNameLong} (${alertTimeZone.offsetNameShort})`}</>}
                zoneAbbreviation={alertTimeZone.offsetNameShort}
                zoneName={alertTimeZone.zoneName}
              />
            </Stack>
          }
        </Stack>
      </CardContent>
    </Card>
  );
};

const ThingCard: FC<ThingCardProps> = ({ thingIds }) => {
  const { thing: customRoutesThing } = useCustomRoutes();
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation(['track']);
  const theme = useTheme();

  return (
    <Card data-testid='thing-card'>
      <CardContent>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {t('terms:thing', { count: thingIds.length })}
        </Typography>

        {thingIds.map((thingId, i: number) => (i === 0 || isExpanded)
          && <Stack key={i}>
            {i !== 0
              && <Divider sx={{ m: '16px 0px 12px 0px' }} />
            }
            <FetchOne
              id={thingId}
              dataType={CacheDataTypes.THING}
              renderFactory={(thing: Thing) => (
                <FetchOneOfAll
                  id={thing.thingTypeId}
                  dataType={CacheDataTypes.THING_TYPE}
                  renderFactory={(thingType: ThingType) => {
                    const entityLink = { display: t('common:common.action.view'), url: `/${customRoutesThing}/${thing._id}` };

                    if (!thing.sharedThingId) {
                      return (
                        <StaticData
                          data-testid="thing-static-data"
                          data={thing}
                          dataType={thingType}
                          entityLink={entityLink}
                        />
                      );
                    }

                    return (
                      <FetchOneOfAll
                        dataType={CacheDataTypes.SHARED_THING_TYPE}
                        id={thingType.sharedThingTypeId}
                        renderFactory={(sharedThingType: SharedThingType) => (
                          <StaticData
                            data-testid="thing-static-data"
                            data={thing}
                            dataType={thingType}
                            entityLink={entityLink}
                            sharedDataType={sharedThingType}
                          />
                        )}
                      />
                    );
                  }}
                />
              )}
            />
          </Stack>
        )}

        {(thingIds.length > 1)
          && <Typography
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{ color: theme.palette.primary.main, cursor: 'pointer', mt: 2 }}
            variant="subtitle1"
          >
            {isExpanded ? t('common:common.action.hideOthers') : t('common:common.labels.othersWithCount', { count: thingIds.length - 1 })}
          </Typography>
        }
      </CardContent>
    </Card>
  );
};

const PersonCard: FC<PersonCardProps> = ({ personIds }) => {
  const { person: customRoutesPerson } = useCustomRoutes();
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation(['track']);

  return (
    <Card data-testid='person-card'>
      <CardContent>
        <Typography sx={{ mb: 2 }} variant="h5">
          {t('terms:person', { count: personIds.length })}
        </Typography>
        {personIds.map((personId, i: number) => (i === 0 || isExpanded)
          && <Stack key={i}>
            {i !== 0
              && <Divider sx={{ m: '16px 0px 12px 0px' }} />
            }
            <FetchOne
              id={personId}
              dataType={CacheDataTypes.PERSON}
              renderFactory={(person: Person) => (
                <FetchOneOfAll
                  id={person.personTypeId}
                  dataType={CacheDataTypes.PERSON_TYPE}
                  renderFactory={(personType: PersonType) => (
                    <StaticData data-testid='person-static-data' data={person} dataType={personType} entityLink={{ display: t('common:common.action.view'), url: `/${customRoutesPerson}/${person._id}` }} />
                  )}
                />
              )}
            />
          </Stack>
        )}
        {(personIds.length > 1)
          && <Button
            onClick={() => setIsExpanded(!isExpanded)}
            size="small"
            sx={{ mt: 2, p: 0 }}
          >
            {isExpanded ? t('common:common.action.hideOthers') : t('common:common.labels.othersWithCount', { count: personIds.length - 1 })}
          </Button>
        }
      </CardContent>
    </Card>
  );
};

const ThingPersonGroupsCard: FC<PersonCardProps & ThingCardProps> = ({ personIds, thingIds }) => {
  const { t } = useTranslation(['track']);

  const thingGroupIds = thingIds.flatMap((thingId) => {
    const thingCache = useFetchOneCache(CacheDataTypes.THING);
    const [result] = usePromise<Undefinable<Thing>>(
      () => thingCache.one<Thing>(thingId),
      [thingCache, thingId],
    );
    return result?.groupIds ?? [];
  });

  const personGroupIds = personIds.flatMap((personId) => {
    const personCache = useFetchOneCache(CacheDataTypes.PERSON);
    const [result] = usePromise<Undefinable<Person>>(
      () => personCache.one<Person>(personId),
      [personCache, personId],
    );
    return result?.groupIds ?? [];
  });

  const thingPersonGroupIds = thingGroupIds.concat(personGroupIds);
  const groupIds = [...new Set(thingPersonGroupIds)];

  return <GroupsCard groupIds={groupIds} noGroupsText={t('track:page.alert-detail.no-thing-person-group.hint')} isClickable />;
};

export const AlertDetail: FC<AlertDetailProps> = ({ alert = undefined }) => {
  const { alerts: customRoutesAlerts, history: customRoutesHistory } = useCustomRoutes();
  const { alertId } = useParams();
  const { userInfo } = useAuthenticated();
  const { t } = useTranslation(['common', 'track']);
  const href = useHref(`/${customRoutesAlerts}`);
  const location = useLocation();
  const navigate = useNavigate();
  const flagThingHistory = useBoolFlag('track-thing-history-feature');
  const { hasAuthorization } = useHasAuthorization();
  const alerts = useFetchOneCache(CacheDataTypes.ALERT);
  const { module, loaded: moduleLoaded } = useDynamicModule<FeatureIcons>('feature-icons', PortalFeatureIcons.Tracking);

  const mediaPermissions = hasAuthorization(MEDIA_PERMISSIONS);

  const loadData = useCallback(
    () => {
      if (!alert) return alerts.one<AlertResponse & { _id: string }>(alertId);
      return Promise.resolve(alert);
    },
    [alertId, userInfo.accountId]
  );

  const [alertData] = usePromise(() => loadData(), [userInfo.accountId]);

  const renderPageContent = (data: AlertResponse): JSX.Element => {
    return <>
      <Stack direction="column" flex={[2, 2]} spacing={2} sx={{ minWidth: 0 }}>
        <AlertCard alert={data} />
        <ThingPersonGroupsCard personIds={data.personIds} thingIds={data.thingIds} />
        {data.thingIds.length !== 0
          && <ThingCard thingIds={data.thingIds} />
        }
        {data.personIds.length !== 0
          && <PersonCard personIds={data.personIds} />
        }
      </Stack>
      <Stack
        direction="column"
        flex={[3, 3]}
        spacing={2}
        sx={{ minWidth: 0 }}
      >
        <AlertMap data={data.data} occurred={data.occurred} />
        {mediaPermissions
          && <FetchOne
            id={data.thingIds[0]}
            dataType={CacheDataTypes.THING}
            renderFactory={(thing: Thing) => (
              <FetchOneOfAll
                id={thing.thingTypeId}
                dataType={CacheDataTypes.THING_TYPE}
                renderFactory={({ features }: ThingType) => (
                  <MediaCard
                    data-testid="alert-media-card"
                    displayOnCard
                    entity={thing}
                    entityTypeFeatures={features}
                    navigateState={getNavigateState(data.occurred)}
                    pageType={MediaListPageType.ALERTS}
                    timeZone={validateLocationType(() => data.data.location)?.timeZone?.id}
                    url={`/api/v2/alert-media-data/alert/${data.alertId}`}
                  />
                )}
              />
            )}
          />}
      </Stack>
    </>;
  };

  const viewHistory = (): void => {
    if (!alertData) return;
    const alertTime = DateTime.fromJSDate(alertData.occurred);

    navigate(`/${customRoutesHistory}`, {
      state: {
        dateEnd: alertTime.plus({ minutes: VIEW_HISTORY_TIME_DIFF }).toJSDate(),
        dateStart: alertTime.minus({ minutes: VIEW_HISTORY_TIME_DIFF }).toJSDate(),
        entityId: alertData.thingIds[0],
        entityItemFields: {
          entity: CacheDataTypes.THING,
          entityType: CacheDataTypes.THING_TYPE,
          entityTypeKey: 'thingTypeId',
        },
        filters: [],
        focused: true,
      },
    });
  };

  const actions = flagThingHistory
    ? [
      {
        label: t('track:common.action.view-history'),
        onClick: viewHistory,
        icon: <DynamicIcon icon={module?.HistoryIcon && <module.HistoryIcon />} />,
      },
    ]
    : [];

  const breadcrumbs = (
    <Breadcrumbs>
      <Link
        color="inherit"
        href={href}
        onClick={(event) => {
          if ((location.state as LocationState).previousUrl === `/${customRoutesAlerts}`) {
            event.preventDefault();
            navigate(-1);
          }
        }}
        underline="hover"
      >
        {t('common:terms.alert', { count: T_MANY })}
      </Link>
      <Typography color="text.primary" data-testid="alert-detail-subtitle-breadcrumb">{t('track:page.alert-detail.title')}</Typography>
    </Breadcrumbs>
  );

  const renderPageAlert = (): JSX.Element => {
    if (!alertData?.suppressed) return <></>;
    return (
      <Alert severity="warning" icon={<NotificationsOffIcon />}>
        {t('common:component.alerts-table.hint.suppression')}
      </Alert>
    );
  };

  const renderPageTitle = ({ alertTypeId, feature, featureTypeId }: AlertType): JSX.Element => {
    const sx: SxProps = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' };
    const featureLabel = t(`common:features.${feature}`);
    const alertTypeLabel = t(`common:alert-descriptions.${featureTypeId}.${alertTypeId}.label`);
    const title = featureTypeId === FeatureTypes.EVENT_RECORD_V0 ? featureLabel : alertTypeLabel;
    const subtitle = featureTypeId === FeatureTypes.EVENT_RECORD_V0 ? alertTypeLabel : featureLabel;
    return <>
      <Typography variant="h4" sx={sx}>{title}</Typography>
      <span style={{ display: 'none' }}> - </span>
      <Typography variant="body2" sx={sx}>{subtitle}</Typography>
    </>;
  };

  if (!moduleLoaded) return <MiddleSpinner />;

  return (
    <Box sx={{
      display: 'flex',
      flex: [1, 1],
      flexDirection: 'column',
      minWidth: 0,
    }}>
      <DetailPage<AlertResponse>
        data-testid='alert-detail'
        actions={actions}
        breadcrumbs={breadcrumbs}
        loadData={loadData}
        pageAlert={renderPageAlert()}
        pageIcon={module?.AlertIcon && <module.AlertIcon />}
        renderDisplay={({ alertTypeId, featureTypeId }) => t('common:event-description', { featureTypeId, eventTypeId: alertTypeId })}
        renderPageContent={renderPageContent}
        renderPageTitle={renderPageTitle}
      />
    </Box>
  );
};
